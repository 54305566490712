import React from 'react'
import Img from 'gatsby-image'
import Cercle from './fingercercle'

const FingerImage = ({ finger }) => (
  <div className={[finger.cell, 'g-cel'].join(' ')}>
    <Cercle />
    <div className="finger">
      {' '}
      <Img className="follow" fixed={finger.image.childImageSharp.fixed} />
    </div>
  </div>
)

export default FingerImage
