import React from 'react'
import { Link } from 'gatsby'
import FingerMobileCSS from './fingermobile.module.css'
import FingerTitle from '../../../components/custom/finger-game/fingertitle'

import VideoTuto from '../../../data/projets/Labo/finger/inc/finger-tuto.mp4'
import VideoTutoWebM from '../../../data/projets/Labo/finger/inc/finger-tuto.webm'

const FingerMobile = () => (
  <div className={FingerMobileCSS.box}>
    <FingerTitle />

    <video
      className={FingerMobileCSS.video}
      muted
      autoPlay
      loop
      data-autoplay
      preload="auto"
    >
      <source src={VideoTutoWebM} type="video/webm" />

      <source src={VideoTuto} type="video/mp4" />
    </video>

    <span className={FingerMobileCSS.texte}>
      <p>
        On joue au doigt dans l'oeil en utilisant la souris. Il n'est pas
        possible d'y jouer avec un téléphone ou une tablette.
      </p>
      <p>Revenez sur cette page depuis un ordinateur pour pouvoir y jouer.</p>

      <p>
        En attendant, allez voir{' '}
        <Link
          activeClassName="active"
          className={FingerMobileCSS.link}
          to="/labo/finger/photos/"
        >
          les photos
        </Link>
        .
      </p>
    </span>
    <div className={FingerMobileCSS.cercle} />
  </div>
)

export default FingerMobile
