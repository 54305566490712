import React from 'react'
import FingerTitleCSS from './fingertitle.module.css'

const FingerTitle = () => (


    <h1 className={FingerTitleCSS.title}>Le doigt dans l'œil</h1>

)

export default FingerTitle
