import React from 'react'
import FingerPhotoLinkCSS from './fingerphotolink.module.css'
import { Link } from 'gatsby'

const FingerTitle = () => (
  <Link
    activeClassName="active"
    className={FingerPhotoLinkCSS.link}
    to="/labo/finger/photos/"
  >
    Les photos!
    <div className={FingerPhotoLinkCSS.arrow} />
  </Link>
)

export default FingerTitle
