import React from 'react';
import CercleCSS from './fingercercle.module.css'

class NewCercle extends React.Component {
randomize(myArray) {
    return myArray[Math.floor(Math.random() * myArray.length)];
}
  render() {
    var arr = ['#FF5252', '#FF4081', '#E040FB', '#7C4DFF', '#536DFE', '#448AFF', '#40C4FF', '#18FFFF', '#64FFDA',
     '#69F0AE', '#B2FF59', '#EEFF41', '#FFFF00', '#FFD740', '#FFAB40', '#FF6E40', '#8D6E63', '#90A4AE', '#FF9A6A',
     '#FFF76A', '#50C29A', '#885BC3', '#FCD0A1', '#B1B695', '#63535B', '#6D1A36', '#D3EFBD', '#2274A5', '#D1495B',
     '#17BEBB', '#D62246', '#B80C09', '#885BC3', '#0B4F6C', '#FEC601']
    return <div 
            style={{
        backgroundColor: `${this.randomize(arr)}`
            }} 
            className={`${CercleCSS.cercle} cercle`}>
            </div>;
  }
}

export default NewCercle

