import React from 'react'
import { Link } from 'gatsby'
import Popup from 'reactjs-popup'
import FingerInfoCSS from './fingerinfo.module.css'

const FingerInfo = () => (
  <Popup
    trigger={
      <div className={FingerInfoCSS.instructions}>
        <div className={FingerInfoCSS.arrow} />
        C'est quoi ce jeu?
      </div>
    }
    modal
    closeOnDocumentClick
    overlayStyle={{
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
    }}
    contentStyle={{
      padding: '25px',
      width: 'auto',
      maxHeight: '75vh',
      overflow: 'overlay',
      transform: 'translateX(155px)',
      backgroundColor: 'rgba(255,255,255, 0.75)',
      boxShadow: '4px 3px',
    }}
  >
    <div className={FingerInfoCSS.texte}>
      <h2 className={FingerInfoCSS.header}>
        Qui n'aime pas jouer avec son doigt?
      </h2>
      <p className={FingerInfoCSS.para}>
        Je me suis amusé à le photographier et maintenant c'est à votre tour de
        vous en emparer avec ce jeu.
      </p>
      <p>
        L'utilisation de l'auto-photographie interactive nous permet de créer un
        lien digital lors d'un geste ludique partagé.
      </p>
      <div className={FingerInfoCSS.sep} />
      <li className={FingerInfoCSS.bullet}>
        Vingt-quatre photographies de mon doigt tournent pour toujours montrer
        le curseur de la souris.
      </li>

      <li className={FingerInfoCSS.bullet}>
        Derrière un doigt choisi au hasard se cache mon oeil.
      </li>

      <li className={FingerInfoCSS.bullet}>
        Un indice coloré apparait sous les autres doigts au survol de la souris.
      </li>

      <li className={FingerInfoCSS.bullet}>
        La taille de l'indice diminue en approchant de l'oeil.
      </li>

      <li className={FingerInfoCSS.bullet}>
        Vous avez découvert l'oeil! Cliquez pour me mettre le doigt dans l'oeil.
      </li>

      <li className={FingerInfoCSS.bullet}>Félicitations, vous avez gagné.</li>

      <li className={FingerInfoCSS.bullet}>
        La page se recharge avec une nouvelle disposition aléatoire des doigts
        et de l'oeil.
      </li>

      <li className={FingerInfoCSS.bullet}>
        Rejouez ou allez voir{' '}
        <Link
          activeClassName="active"
          className={FingerInfoCSS.photolink}
          to="/labo/finger/photos/"
        >
          les photos
        </Link>
        .
      </li>
    </div>
  </Popup>
)

export default FingerInfo
