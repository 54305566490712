import React from 'react'
import Layout from '../../../components/layout'
import Content from '../../../components/content'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import lifecycle from 'react-pure-lifecycle'
import $ from 'jquery'

import FingerImage from '../../../components/custom/finger-game/fingerimage'
import FingerTitle from '../../../components/custom/finger-game/fingertitle'
import Sidebar from '../../../components/Sidebar'
import FingerInfo from '../../../components/custom/finger-game/fingerinfo'
import FingerPhotoLink from '../../../components/custom/finger-game/fingerphotolink'
import FingerMobile from '../../../components/custom/finger-game/fingermobile'

import '../../../components/custom/finger-game/finger.css'

// create your lifecycle methods
const componentDidMount = props => {
  //***RANDOM FINGER ***//

  $(function() {
    var div = $('.g-cel').toArray()
    while (div.length > 0) {
      var idx = Math.floor(Math.random() * div.length)
      var element = div.splice(idx, 1)
      $('.game-grid').append(element[0])
    }

    //*** EYE RANDOM ***//
    var number = $('.g-cel').length
    var random = Math.floor(Math.random() * number)
    $('.g-cel')
      .eq(random)
      .addClass('random-eye')
  })

  //***!! CIRCLE SIZE ***//
  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-01')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-02')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-03')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-04')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-05')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-06')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-07')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-08')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-08')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-09')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-10')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-11')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-12')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-13')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-14')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-15')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-16')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-17')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-18')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-19')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })
  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-20')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-21')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-22')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-23')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  $(function() {
    var distmaxs = 150
    var distmaxl = 300
    var eye = $('.random-eye')
    var eyetop = eye.offset().top
    var eyeleft = eye.offset().left

    var cerc = $('.g-cel-24')
    var cerctop = cerc.offset().top
    var cercleft = cerc.offset().left

    var vdist = Number(cerctop) - Number(eyetop)
    var hdist = Number(cercleft) - Number(eyeleft)

    if ((Math.abs(hdist) < distmaxs) & (Math.abs(vdist) < distmaxs)) {
      $(cerc).addClass('dis-01')
    }
    if ((Math.abs(hdist) < distmaxl) & (Math.abs(vdist) < distmaxl)) {
      $(cerc).addClass('dis-02')
    }
  })

  //*** EYE CLICK ***//
  $(function() {
    $('.g-cel').mouseover(function() {
      $(this)
        .find('.finger')
        .hide(0)
    })
    $('.g-cel')
      .not('.random-eye')
      .mouseout(function() {
        $(this)
          .find('.finger')
          .show(0)
      })
    $('.g-cel')
      .not('.random-eye')
      .click(function() {
        $(this)
          .find('.cercle')
          .addClass('clicked')
        $(this)
          .find('.finger')
          .css({
            visibility: 'hidden',
          })
      })

    $('.random-eye').mouseover(function() {
      $(this).css({
        transform: 'scale(1.1) rotate(360deg)',
        transition: 'all .5s ease-out',
      })
    })

    $('.random-eye').mousedown(function() {
      window.location.reload()
    })
  })

  //*** FOLLOW FINGER ***//
  $(function() {
    var box = $('.g-cel-01')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]

    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-01 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-02')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-02 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-03')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-03 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-04')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-04 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-05')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-05 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-06')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-06 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-07')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-07 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-08')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-08 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-09')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-09 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-10')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-10 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-11')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-11 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-12')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-12 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-13')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-13 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-14')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-14 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-15')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-15 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-16')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-16 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-17')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-17 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-18')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-18 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-19')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-19 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-20')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-20 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-21')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-21 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-22')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-22 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-23')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-23 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
  $(function() {
    var box = $('.g-cel-24')
    var boxCenter = [
      box.offset().left + box.width() / 2,
      box.offset().top + box.height() / 2,
    ]
    $(document).mousemove(function(e) {
      var angle =
        Math.atan2(e.pageX - boxCenter[0], -(e.pageY - boxCenter[1])) *
        (180 / Math.PI)
      $('.g-cel-24 .finger').css({ transform: 'rotate(' + angle + 'deg)' })
    })
  })
}

// make them properties on a standard object
const methods = {
  componentDidMount,
}

const FingerPage = ({
  data: {
    allFingerJson: { edges },
    markdownRemark: { frontmatter },
    site: { siteMetadata },
  },
}) => {
  const Images = edges.map(edge => (
    <FingerImage key={edge.node.id} finger={edge.node.item} />
  ))

  return (
    <>
      <Helmet
        title={`${frontmatter.title} - ${siteMetadata.title}`}
        bodyAttributes={{
          class: 'finger-page',
          id: 'fingeroverlay',
        }}
        defer={false}
      >
        <meta property="og:title" content={frontmatter.title} />
        <meta
          property="og:image"
          content={`${siteMetadata.siteUrl}${frontmatter.featured.photoog.fixed.src}`}
        />
      </Helmet>
      <Layout>
        <Sidebar />
        <Content>
          <div className="game-grid">
            <FingerTitle />
            {Images}
          </div>
          <FingerInfo />
          <FingerPhotoLink />
          <FingerMobile />
        </Content>
      </Layout>
    </>
  )
}

export const soloQuery = graphql`
  query fingerQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allFingerJson {
      edges {
        node {
          id
          item {
            cell
            image {
              childImageSharp {
                fixed(width: 120, quality: 70) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: { idu: { eq: "fin" } }) {
      frontmatter {
        title
        featured {
          photoog: childImageSharp {
            fixed(width: 1920, quality: 75) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

export default lifecycle(methods)(FingerPage)
